import { Dialog as StandaloneDialog } from '@/components/Dialog';
import { ArticlePaywallForm as StandaloneArticlePaywallForm } from '@/components/standalone/ArticlePaywall/Form';
import { ArticlePaywallSuccessfulPayment } from '@/components/standalone/ArticlePaywall/SuccessfulPayment';
import { ArticlePaywallTheme } from '@/components/standalone/ArticlePaywall/theme';
import { KlarnaPayment as StandaloneKlarnaPayment } from '@/components/standalone/KlarnaPayment';
import { GenericSlot, GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: ArticlePaywallTheme });

const Base = $({ as: 'section', slot: 'base' });
const Description = $({ as: 'p', slot: 'description' });
const Headline = $({ as: 'h2', slot: 'headline' });
const Logo = $({ as: 'div', slot: 'logo' });
const Dialog = GenericSlot({ as: StandaloneDialog, theme: ArticlePaywallTheme.Dialog });
const Form = GenericSlot({ as: StandaloneArticlePaywallForm, theme: ArticlePaywallTheme.Form });
const KlarnaPayment = GenericSlot({ as: StandaloneKlarnaPayment, theme: ArticlePaywallTheme.KlarnaPayment });
const SuccessfulPayment = GenericSlot({
  as: ArticlePaywallSuccessfulPayment,
  theme: ArticlePaywallTheme.SuccessfulPayment,
});

export const ArticlePaywall = Object.assign(Base, {
  Description,
  Headline,
  Logo,
  Dialog,
  Form,
  KlarnaPayment,
  SuccessfulPayment,
});
