import { ExpertSliderProps } from '@/components/ExpertSlider';
import HelenaWiklundImage from '@/svenskdam/public/image/experts/helena-wiklund.png';
import HermanLindqvistImage from '@/svenskdam/public/image/experts/herman-lindqvist.png';
import JohanTLindwallImage from '@/svenskdam/public/image/experts/johan-t-lindwall.png';
import { mergeProps } from '@/utils/merge';
import { StandaloneExpertSlider } from 'base/components/ExpertSlider';

const slides: ExpertSliderProps['slides'] = [
  {
    image: { src: HermanLindqvistImage, alt: 'Historiker' },
    caption: 'Historiker',
    description: 'Välkända Herman Lindqvist berättar om kungarnas och drottningarnas historia',
    href: '/vara-skribenter/herman_lindqvist',
  },
  {
    image: { src: HelenaWiklundImage, alt: 'Hovreporter' },
    caption: 'Hovreporter',
    description: 'Vassa Helena Wiklund rapporterar om allt det senaste från insidan av slottet',
    href: '/vara-skribenter/helena_wiklund',
  },
  {
    image: { src: JohanTLindwallImage, alt: 'Chefredaktör' },
    caption: 'Chefredaktör',
    description: 'Johan T. Lindwall är vår kunniga chefredaktör. Det han inte vet om hovet är inte värt att veta',
    href: '/vara-skribenter/johan_t_lindwall',
  },
];

export const SvenskdamStandaloneExpertSlider: typeof StandaloneExpertSlider = (props) => {
  return <StandaloneExpertSlider {...mergeProps(props, { headline: 'Våra Hovexperter', slides })} />;
};
