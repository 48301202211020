import { tw } from '@/utils/tw';
import BaseExpertSliderTheme from 'base/components/ExpertSlider/theme';

const Slider = tw.theme({
  extend: BaseExpertSliderTheme.Slider,
});

const Image = tw.theme({
  extend: BaseExpertSliderTheme.Image,
  slots: {
    base: ['group-even/slide:bg-secondary-500', 'group-odd/slide:bg-primary-700', 'data-[fill]:!aspect-[0.72/1]'],
  },
});

const ExpertSlider = tw.theme({
  extend: BaseExpertSliderTheme,
  slots: {
    headline: ['text-headline-3xs'],
    caption: ['text-primary-700', 'text-headline-3xs'],
    description: ['text-headline-2xs', 'sm:text-headline-xs', 'md:text-headline-sm'],
  },
});

export default Object.assign(ExpertSlider, { Image, Slider });
