import { ArticlePaywallFormTheme } from '@/components/ArticlePaywall/Form/theme';
import { DialogTheme } from '@/components/Dialog/theme';
import { KlarnaPaymentTheme } from '@/components/KlarnaPayment/theme';
import { ArticlePaywallSuccessfulPaymentTheme } from '@/components/standalone/ArticlePaywall/SuccessfulPayment/theme';
import { tw } from '@/utils/tw';

const ArticlePaywallTheme = tw.theme({
  slots: {
    base: ['not-prose'],
    description: [],
    headline: [],
    logo: [],
  },
});

export default Object.assign(ArticlePaywallTheme, {
  Dialog: DialogTheme,
  Form: ArticlePaywallFormTheme,
  KlarnaPayment: KlarnaPaymentTheme,
  SuccessfulPayment: ArticlePaywallSuccessfulPaymentTheme,
});
