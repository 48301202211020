import { tw } from '@/utils/tw';
import BaseAuthorsListTheme from 'base/components/AuthorsList/theme';

const ListItemImage = tw.theme({
  extend: BaseAuthorsListTheme.ListItemImage,
  slots: {
    base: ['rounded-full'],
  },
});

const AuthorsListTheme = tw.theme({
  extend: BaseAuthorsListTheme,
  slots: {
    base: ['mb-2', 'sm:mb-4', 'md:mb-6', 'mx-4'],
    headline: ['text-headline-lg', 'sm:text-headline-2xl', 'mt-2'],
    description: ['text-body-sm', 'max-w-4xl'],
    list: ['grid', 'grid-cols-1', 'sm:grid-cols-2', 'md:grid-cols-3'],
    listItem: ['rounded', 'w-full'],
    listItemTitle: ['text-headline-xs'],
  },
  variants: {
    colors: {
      default: { listItem: ['bg-gray-100', 'hover:bg-gray-50', 'active:bg-gray-200'] },
    },
  },
});

export default Object.assign(AuthorsListTheme, {
  ListItemLink: BaseAuthorsListTheme.ListItemLink,
  ListItemImage,
  ListItemIcon: BaseAuthorsListTheme.ListItemIcon,
});
